<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
  >
    <g transform="translate(0 0)">
      <rect width="30" height="30" rx="6" fill="#068ac9" />
      <path
        d="M187.848,73.593a9.468,9.468,0,0,0-2.356-3.215A11.407,11.407,0,0,0,182,68.191a14.705,14.705,0,0,0-5.5-.989,13.679,13.679,0,0,0-11,5.595,12.118,12.118,0,0,0-2.221,5.388,11.19,11.19,0,0,0,.823,6.046.483.483,0,0,0,.447.283.513.513,0,0,0,.132-.017.449.449,0,0,0,.342-.511,13.452,13.452,0,0,1-.107-3.264,10.9,10.9,0,0,1,2.367-6.134c1.964-2.316,5.074-3.6,9.248-3.824,0,0,.077,0,.214,0a10.554,10.554,0,0,1,3.45.628,9.39,9.39,0,0,1,4.7,3.638.491.491,0,0,0,.408.21.508.508,0,0,0,.226-.053l2.1-1.041A.437.437,0,0,0,187.848,73.593Z"
        transform="translate(-161.403 -63.77)"
        fill="#fff"
      />
      <path
        d="M375.257,229.912a1.037,1.037,0,0,0-.962-.585,1.844,1.844,0,0,0-.822.225l-11.024,5.4a1.859,1.859,0,1,0-.788,3.542,1.872,1.872,0,0,0,1.751-1.238c1.475-.735,11.009-5.488,11.343-5.692A1.1,1.1,0,0,0,375.257,229.912Zm-13.575,7.465a.732.732,0,1,1,.732-.732A.732.732,0,0,1,361.681,237.377Z"
        transform="translate(-347.044 -217.628)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
